import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      position: 'fixed'
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `26px 20px`,
      }}
    >
      <h1 style={{
          margin: 0,
          fontSize: 25,
          fontWeight: 'normal',
      }}>
          <span>Andrew Osipov</span>
          <span style={{
              display: 'block',
              fontSize: 15,
              fontWeight: 'normal'
          }}>
              Frontend JavaScript Developer
          </span>
      </h1>
        <div className="links">
            <a href="https://www.linkedin.com/in/andrew-osipov/" target="_blank">LinkedIn</a>
        </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
